<section class="d-flex row vh-100">
    <div class="vh-100 bg-secondary p-5 col-7 d-none d-lg-block" :class="{'bg-dark':requestData.interface.toLowerCase() == 'merchant'}">
        <img :src="requestData.interface.toLowerCase() == 'pos' ? require(`../../../assets/images/auth-bg.svg`) : require(`../../../assets/images/merchant-auth-bg.svg`)" alt="B4UFinancial" class="img-fluid h-100 d-block mx-auto">
    </div>
    <div class="vh-100 col col-lg-5 d-flex flex-column justify-content-center align-items-lg-baseline align-items-center p-5 overflow-auto" style="background: aliceblue;">
        <img src="../../../assets/images/b4ufinancial.svg" width="290" alt="B4UFinancial Logo">
        <div v-if="!isCheckingRequest && isValidRequest" style="width: fit-content;">
            <h1 class="fw-bold mt-5 text-center text-lg-start" :class="{'fa-2x':requestData.interface.toLowerCase() == 'merchant'}">{{ requestData.interface.toLowerCase() == 'merchant'?'Merchant Administrator Login':requestData.interface.toLowerCase() == 'pos' ? 'Terminal Login': 'Login'}}</h1>
            <p class="fw-bold text-black-50 mb-5 text-center text-lg-start">Authenticate your account</p>
            <Form class="form-signin" @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
                <input type="hidden" autocomplete="off">
                <div class="input-group mb-3">
                    <Field type="text" name="email" class="form-control form-control-lg rounded-pill" :class="{ 'is-invalid': errors.email }" placeholder="Username" autocomplete="off" />
                    <div class="invalid-feedback ps-3">{{errors.email}}</div>
                </div>
                <div class="input-group mb-3">
                    <Field type="password" name="password" class="form-control form-control-lg rounded-pill" :class="{ 'is-invalid': errors.password }" placeholder="Password" />
                    <div class="invalid-feedback ps-3">{{errors.password}}</div>
                </div>
                <div class="input-group mb-6" v-if="showRecaptcha">
                    <vue-recaptcha :siteKey="recaptchaSiteKey" size="normal" theme="light" :tabindex="0" @verify="recaptchaVerified" @expire="recaptchaExpired" @fail="recaptchaFailed" ref="vueRecaptcha">
                    </vue-recaptcha>
                </div>

                <div class="mt-5 text-center text-lg-start">
                    <button :disabled="recaptchaToken == null && showRecaptcha == true" class="btn btn-lg btn-secondary rounded-pill px-5 me-3 text-uppercase" type="submit">
                        <span v-if="loading">
                            <i class="fas fa-circle-notch fa-spin"></i>
                        </span>
                        <span v-if="!loading">
                            <i class="fas fa-sign-in-alt me-2"></i> Login
                        </span>
                    </button>
                    <!-- <a href="">Request for new account</a> -->
                </div>
                <div class="loginErrorMessage my-4" v-if="loginError != null">
                    {{loginError}}
                </div>
            </Form>
        </div>
        <div v-if="!isCheckingRequest && !isValidRequest">
            <p class="fw-bold text-black-50 mt-4">You came here wrongly. <br /> Go to b4ufinancial.com and click login</p>
            <!-- <div class="alert alert-danger" role="alert" v-for="_err in requestErrors">
                {{_err}}
            </div> -->
        </div>
    </div>
</section>