<template src="./login.html" />
<script >
import { ref } from 'vue';
import { Form, Field } from 'vee-validate';
import queryString from 'query-string';
import vueRecaptcha from 'vue3-recaptcha2';
import { get } from 'axios';
import config from "../../../config/appConfig";
import {
    authSchema,
    formSchema
} from './validate'

export default {
    components: {
        Form,
        Field,
        vueRecaptcha,
    },
    data(){
        return {
            isCheckingRequest: true,
            isValidRequest: false,
            requestErrors: [],
            recaptchaSiteKey: config.recaptchaSiteKey,
        }
    },
    setup() {

        const token = ref(null);
        const loading = ref(false);
        const loginError = ref(null);

        const showRecaptcha = ref(false);
        const vueRecaptcha = ref(null);
        const recaptchaToken = ref(null);
        
        const requestData = queryString.parse(window.location.search);
        if(requestData.qr && requestData.qr != ''){
            showRecaptcha.value = true;
        }

        const onSubmit = values => {
            loading.value = true;
            loginError.value = null;
            get(`${config.api_url}/authentication/login`, {
                auth: {
                    username: values.email,
                    password: values.password
                },
                headers: {
                    'Device-Type': showRecaptcha.value ? 'ComputerDesktop' : 'MobileApp',
                    'X-Recaptcha-Token': recaptchaToken.value,
                }
            })
            .then((response) => {
                if(response.data.status == 'OK'){
                    let accessToken = response.headers.authorization;
                    window.location.href = `${requestData.redirect_url}?access_token=${encodeURIComponent(accessToken)}&auth_token=${encodeURIComponent(requestData.auth_token)}&mode=${encodeURIComponent(requestData.mode)}&interface=${encodeURIComponent(requestData.interface)}`;
                }
                else{
                    recaptchaToken.value = null;
                    loading.value = false;
                    loginError.value = 'Something went wrong';
                    vueRecaptcha.value.reset();
                }
            })
            .catch((error) => {
                recaptchaToken.value = null;
                loading.value = false;
                if(error.response.status == 401){
                    loginError.value = 'Unauthorized';
                }
                else{
                    loginError.value = (error.response.data.error && typeof error.response.data.error) === 'string' ? error.response.data.error : error.message;
                }
                vueRecaptcha.value.reset();
            });
        }

        const recaptchaVerified = (response) => {
            console.log(response);
            recaptchaToken.value = response;
        }
        const recaptchaExpired = () => {
            recaptchaToken.value = null
            vueRecaptcha.value.reset();
        }
        const recaptchaFailed = () => {
            recaptchaToken.value = null;
        }

        return {
            schema: formSchema,
            onSubmit,
            requestData,
            showRecaptcha,
            recaptchaVerified,
            recaptchaExpired,
            recaptchaFailed,
            vueRecaptcha,
            recaptchaToken,
            token,
            loading,
            loginError
        };
    },
    mounted() {
        const requestDataQuery = queryString.parse(window.location.search);
        authSchema.validate(requestDataQuery, { abortEarly: false }).then(() => {
            this.isValidRequest = true;
            this.isCheckingRequest = false;
        }).catch( (err) => {
            let errors = [];
            err.inner.forEach(e => {
                errors.push(e.message);
                //console.log(e.message, e.path);
            });
            this.isCheckingRequest = false;
            this.requestErrors = errors;
        });
    },
}
</script>

<style scoped src="./login.css" />