import * as Yup from 'yup';

const authSchema = Yup.object().shape({
    auth_token: Yup.string()
        .required('auth_token is required.'),
    redirect_url: Yup.string()
        .matches(
            // /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            /^https?:\/\/\w+(\.\w+)*(:[0-9]+)?\/?(\/[.\w]*)*$/,
            'Enter valid redirect_url.'
        )
        .required('redirect_url is required.'),
    mode: Yup.string()
        .oneOf(['TEST', 'PROD'], 'Provide valid mode')
        .required('mode is required'),
    interface: Yup.string()
        .oneOf(['USER', 'MERCHANT', 'ADMIN', 'SUPER_ADMIN', 'ATM', 'INVOICE', 'POS'], 'Provide valid interface')
        .required('interface is required')
});

const formSchema = Yup.object().shape({
    email: Yup.string()
        .required('Username is required'),
    password: Yup.string()
        .required('Password is required'),
});

export {
    authSchema,
    formSchema
}