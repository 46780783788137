<template>
  <div><Login /></div>
</template>

<script>
import Login from "./components/auth/login/login";
export default {
  name: "App",
  components: { Login },
};
</script>
<style lang="scss">
  @import './assets/css/styles.scss';
</style>
